.netliva-switch {
  display: inline-block;
  vertical-align: middle;
}

.netliva-switch input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute; }

.netliva-switch label {
  cursor: pointer;
  font-size: 14px;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  --switch-passive-color: #bbb;
  background: var(--switch-passive-color);
  display: block;
  border-radius: 100px;
  position: relative;
  margin: 0; }

.netliva-switch input:checked + label {
  --switch-active-color: #bbb; /* custom */
  background: var(--switch-active-color); }

.netliva-switch input:disabled + label {
  opacity: 0.6; }

.netliva-switch label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s; }

.netliva-switch input:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%); }

.netliva-switch input:not(:disabled) + label:active:after {
  width: 90%;
  width: calc(100% - 4px); }

.netliva-switch input + label:before {
  content: attr(data-passive-text);
  left: 30px;
  right: 10px;
  text-align: right;
  justify-content: flex-end;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  position: absolute;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
  font-size: 14px; }

.netliva-switch input:checked + label:before {
  content: attr(data-active-text);
  left: 10px;
  right: 30px;
  text-align: left;
  justify-content: flex-start; }

.netliva-switch input[data-size="mini"] + label {
  height: 16px; }

.netliva-switch input[data-size="mini"] + label:after {
  height: 12px;
  width: 12px; }

.netliva-switch input[data-size="mini"] + label::before {
  top: 1px;
  bottom: 1px;
  font-size: 10px;
  left: 10px;
  right: 5px; }

.netliva-switch input[data-size="mini"]:checked + label:before {
  left: 5px;
  right: 10px; }

.netliva-switch input[data-size="mini"] + label {
  font-size: 10px; }

.netliva-switch input[data-size="small"] + label {
  height: 20px; }

.netliva-switch input[data-size="small"] + label:after {
  height: 16px;
  width: 16px; }

.netliva-switch input[data-size="small"] + label::before {
  top: 1px;
  bottom: 1px;
  font-size: 10px;
  left: 10px;
  right: 5px; }

.netliva-switch input[data-size="small"]:checked + label:before {
  left: 5px;
  right: 10px; }

.netliva-switch input[data-size="small"] + label {
  font-size: 10px; }

.netliva-switch input[data-active-color="green"] + label {
  --switch-active-color: #03985d; }

.netliva-switch input[data-active-color="aqua"] + label {
  --switch-active-color: #00c0ef; }

.netliva-switch input[data-active-color="maroon"] + label {
  --switch-active-color: #D81B60; }

.netliva-switch input[data-passive-color="green"] + label {
  --switch-passive-color: #03985d; }