.row-horizon {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.row-horizon > [class*="col-lg"], .row-horizon > [class*="col-md"], .row-horizon > [class*="col-sm"], .row-horizon > [class*="col-xs"] {
  float: none;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.row-horizon > .col-xs-12 {
  width: 90%;
}
.row-horizon > .col-xs-11 {
  width: 82.5%;
}
.row-horizon > .col-xs-10 {
  width: 75%;
}
.row-horizon > .col-xs-9 {
  width: 67.5%;
}
.row-horizon > .col-xs-8 {
  width: 60%;
}
.row-horizon > .col-xs-7 {
  width: 52.5%;
}
.row-horizon > .col-xs-6 {
  width: 45%;
}
.row-horizon > .col-xs-5 {
  width: 37.5%;
}
.row-horizon > .col-xs-4 {
  width: 30%;
}
.row-horizon > .col-xs-3 {
  width: 22.5%;
}
.row-horizon > .col-xs-2 {
  width: 15%;
}
.row-horizon > .col-xs-1 {
  width: 7.5%;
}
@media (min-width: 768px) {
  .row-horizon > .col-sm-12 {
    width: 90%;
  }
  .row-horizon > .col-sm-11 {
    width: 82.5%;
  }
  .row-horizon > .col-sm-10 {
    width: 75%;
  }
  .row-horizon > .col-sm-9 {
    width: 67.5%;
  }
  .row-horizon > .col-sm-8 {
    width: 60%;
  }
  .row-horizon > .col-sm-7 {
    width: 52.5%;
  }
  .row-horizon > .col-sm-6 {
    width: 45%;
  }
  .row-horizon > .col-sm-5 {
    width: 37.5%;
  }
  .row-horizon > .col-sm-4 {
    width: 30%;
  }
  .row-horizon > .col-sm-3 {
    width: 22.5%;
  }
  .row-horizon > .col-sm-2 {
    width: 15%;
  }
  .row-horizon > .col-sm-1 {
    width: 7.5%;
  }
}
@media (min-width: 992px) {
  .row-horizon > .col-md-12 {
    width: 90%;
  }
  .row-horizon > .col-md-11 {
    width: 82.5%;
  }
  .row-horizon > .col-md-10 {
    width: 75%;
  }
  .row-horizon > .col-md-9 {
    width: 67.5%;
  }
  .row-horizon > .col-md-8 {
    width: 60%;
  }
  .row-horizon > .col-md-7 {
    width: 52.5%;
  }
  .row-horizon > .col-md-6 {
    width: 45%;
  }
  .row-horizon > .col-md-5 {
    width: 37.5%;
  }
  .row-horizon > .col-md-4 {
    width: 30%;
  }
  .row-horizon > .col-md-3 {
    width: 22.5%;
  }
  .row-horizon > .col-md-2 {
    width: 15%;
  }
  .row-horizon > .col-md-1 {
    width: 7.5%;
  }
}
@media (min-width: 1200px) {
  .row-horizon > .col-lg-12 {
    width: 90%;
  }
  .row-horizon > .col-lg-11 {
    width: 82.5%;
  }
  .row-horizon > .col-lg-10 {
    width: 75%;
  }
  .row-horizon > .col-lg-9 {
    width: 67.5%;
  }
  .row-horizon > .col-lg-8 {
    width: 60%;
  }
  .row-horizon > .col-lg-7 {
    width: 52.5%;
  }
  .row-horizon > .col-lg-6 {
    width: 45%;
  }
  .row-horizon > .col-lg-5 {
    width: 37.5%;
  }
  .row-horizon > .col-lg-4 {
    width: 30%;
  }
  .row-horizon > .col-lg-3 {
    width: 22.5%;
  }
  .row-horizon > .col-lg-2 {
    width: 15%;
  }
  .row-horizon > .col-lg-1 {
    width: 7.5%;
  }
}