html {
	height: 100%;
	}
body {
	margin: 0;
	padding: 0;
	height: 100%;
	background: #efefef;
	color: #222;
	font: 13px/22px 'Helvetica Neue', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	}

.mindmap {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	width: 100%;
	height: 100%;
	text-align: center;
	white-space: nowrap;
	}
.mindmap:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	width: 0;
	}

.node {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 22px;
	border: 1px solid #ccc;
	padding: 0 4px;
	margin: 2px 0;
	background: #fff;
	border-radius: 2px;
	text-align: left;
	cursor: pointer;
	transition: all .2s;
	direction: ltr;
	}
.node:hover {
	box-shadow: 0 0 3px 0 #3498DB;
	}
.node:active {
	box-shadow: 0 0 1px 0 #3498DB;
	}
	.node__input {
		border: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		padding: 0 3px 0 4px;
		top: 0;
		right: 0;
		left: 0;
		height: 22px;
		font: inherit;
		display: none;
		background: none;
		color: inherit;
		}
	.node__input:focus {
		outline: 0;
		box-shadow: inset 0 0 2px 1px #3498DB;
		}

.node_active,
.node_active:hover {
	box-shadow: 0 0 2px 1px #3498DB;
	}

.node_root {
	border: 0;
	background: #3498DB;
	color: #fff;
	font: 18px/32px 'Helvetica Neue', Helvetica, Arial, sans-serif;
	padding: 0 10px;
	height: 32px;
	}
	.node_root .node__input {
		height: 32px;
		padding: 0 9px 0 10px;
		}

.node_editable {
	border: 1px solid #3498DB;
	}
.node_editable.node_root {
	border: 0;
	}
	.node_editable .node__text {
		height: 0;
		overflow: hidden;
		}
	.node_editable .node__input {
		display: block;
		}


.children {
	position: relative;
	list-style: none;
	margin: 0;
	text-align: left;
	padding: 0 0 0 6px;
	display: inline-block;
	vertical-align: middle;
	}
.children:before {
	content: '';
	position: absolute;
	top: 50%;
	left: -4px;
	width: 10px;
	border-top: 1px solid #ccc;
	}
	.children__item {
		position: relative;
		padding: 0 0 0 10px;
		border-left: 1px solid #ccc;
		}
	.children__item:first-child,
	.children__item:last-child {
		border-left: 1px solid transparent;
		}
	.children__item:before {
		content: '';
		width: 10px;
		border-top: 1px solid #ccc;
		position: absolute;
		top: 50%;
		left: 0;
		}
	.children__item:first-child:before {
		left: -1px;
		bottom: 0;
		border-radius: 4px 0 0 0;
		border-left: 1px solid #ccc;
		}
	.children__item:last-child:before {
		left: -1px;
		top: 0;
		bottom: 50%;
		border-radius: 0 0 0 4px;
		border: 0;
		border-left: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		}
	.children__item:first-child:last-child:before {
		top: 50%;
		bottom: auto;
		border: 0;
		border-radius: 0;
		border-top: 1px solid #ccc;
		}

.children_leftbranch,
.children_leftbranch .children {
	direction: rtl;
	text-align: right;
	padding: 0 6px 0 0;
	}
.children_leftbranch:before,
.children_leftbranch .children:before {
	left: auto;
	right: -4px;
	}
	.children_leftbranch .children__item {
		padding: 0 10px 0 0;
		border: 0;
		border-right: 1px solid #ccc;
		}
	.children_leftbranch .children__item:first-child,
	.children_leftbranch .children__item:last-child {
		border-right: 1px solid transparent;
		}
	.children_leftbranch .children__item:before {
		top: 50%;
		left: auto;
		right: 0;
		}
	.children_leftbranch .children__item:first-child:before {
		right: -1px;
		border: 0;
		border-radius: 0 4px 0 0;
		border-top: 1px solid #ccc;
		border-right: 1px solid #ccc;
		}
	.children_leftbranch .children__item:last-child:before {
		right: -1px;
		top: 0;
		bottom: 50%;
		border-radius: 0 0 4px 0;
		border: 0;
		border-right: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		}
	.children_leftbranch .children__item:first-child:last-child:before {
		top: 50%;
		bottom: auto;
		border: 0;
		border-radius: 0;
		border-top: 1px solid #ccc;
		}